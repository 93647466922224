import {func, string, bool, object} from 'prop-types';
import {Button, Typography} from 'antd';

import {AppRowContainer} from '@crema';
import NoDataImg from '../../assets/images/NoDataImg.svg';
import AppPageWrapper from './AppPageWrapper';
import AppIcon from './AppIcon';

const {Title} = Typography;

const NoDataCmp = ({title, showButton, text, icon, onClickFunction}) => {
  return (
    <AppPageWrapper>
      <AppRowContainer
        style={{
          margin: '4rem',
          height: '30rem',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <img
          src={NoDataImg}
          width='150rem'
          height='auto'
          alt='Image'
          style={{paddingBottom: '1rem'}}
        />
        <div style={{paddingBottom: '1rem'}}>
          <Title level={5}>{title}</Title>
        </div>

        {showButton && (
          <Button
            type='primary'
            style={{display: 'flex', alignItems: 'center', gap: '.5rem'}}
            icon={icon}
            onClick={onClickFunction}>
            {text}
          </Button>
        )}
      </AppRowContainer>
    </AppPageWrapper>
  );
};
NoDataCmp.propTypes = {
  title: string,
  showButton: bool,
  text: string,
  icon: object,
  onClickFunction: func,
};

NoDataCmp.defaultProps = {
  showButton: false,
  text: 'Button Text',
  icon: <AppIcon tag='eva:plus-fill' />,
};

export default NoDataCmp;
