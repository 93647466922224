import {useMutation, useQuery, useQueryClient} from 'react-query';
import {NotificationManagerAPI, RoleManagerAPI} from 'utils/ApiConfig';
import ErrorHandler from 'utils/ErrorHandler';
import {getItem} from 'utils/Helper';
import Notification from 'utils/Notification';

let queryOptions = {
  onError: ErrorHandler,
};

export const sendNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (body) =>
      NotificationManagerAPI.post('/notification/create-notification', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      ...queryOptions,
      onSuccess: () => {
        queryClient.invalidateQueries('get-all-notification');
        Notification('Notification has been scheduled.', 'success');
      },
    },
  );
};

export const getAllNotification = () => {
  return useQuery(
    'get-all-notification',
    async () => {
      const notifications = await NotificationManagerAPI.get(
        '/notification/get-manual-notifications',
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')} `,
          },
        },
      );

      return notifications?.data.data;
    },
    queryOptions,
  );
};

export const deleteNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      return NotificationManagerAPI.delete(
        `/notification/delete-manual-notification/${id}`,

        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')}`,
          },
        },
      );
    },
    ...queryOptions,
    onSuccess() {
      Notification('Notification Deleted.', 'success');
      queryClient.invalidateQueries('get-all-notification');
    },
  });
};

export const getAllRoleType = () => {
  return useQuery({
    queryKey: 'get-all-role-type',
    queryFn: async () => {
      const roleList = await RoleManagerAPI.get('roles/role-list', {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      });

      return roleList?.data.data;
    },
    ...queryOptions,
  });
};

export const getAllNotificationUser = () => {
  return useMutation({
    mutationFn: (body) => {
      return NotificationManagerAPI.post(
        '/notification/get-users-by-location',
        body,
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')}`,
          },
        },
      );
    },
    ...queryOptions,
  });
};

export const getUsersByCampaigns = () => {
  return useMutation({
    mutationFn: (body) => {
      return NotificationManagerAPI.post(
        '/notification/get-users-by-campaign',
        body,
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')}`,
          },
        },
      );
    },
    ...queryOptions,
  });
};

export const getAllCampaigns = () => {
  return useQuery({
    queryKey: 'get-all-campaigns',
    queryFn: async () => {
      const campaigns = await NotificationManagerAPI.get(
        '/notification/campaigns',
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')} `,
          },
        },
      );

      return campaigns?.data.data;
    },
    ...queryOptions,
  });
};

export const getAllNotifications = (isLoggedin) => {
  return useQuery(
    'notifications',
    () =>
      NotificationManagerAPI.get('/notification/get-notifications', {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      ...queryOptions,
      enabled: isLoggedin,
      select: ({data: {data}}) => data,
    },
  );
};

export const viewAllNotifications = () => {
  return useMutation(
    (ids) =>
      NotificationManagerAPI.post(
        `/notification/bulk-seen-notification`,
        {ids},
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')} `,
          },
        },
      ),
    queryOptions,
  );
};

export const getSingleNotificationInfo = (notificationId) => {
  return useQuery({
    queryKey: ['single-notification-info', notificationId],
    queryFn: async ({queryKey}) => {
      const notificationInfo = await NotificationManagerAPI.get(
        `/notification/get-manual-notification/${queryKey[1]}`,
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')}`,
          },
        },
      );
      return notificationInfo?.data?.data;
    },
    ...queryOptions,
  });
};

export const updateSingleNotification = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body) => {
      return NotificationManagerAPI.put(
        `/notification/update-manual-notification/${id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')} `,
          },
        },
      );
    },
    ...queryOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(['single-notification-info', id]);
    },
  });
};
