import PropTypes from 'prop-types';
import {Navigate, useLocation} from 'react-router-dom';

import Notification from '../utils/Notification';
import {Accesses} from '../utils/Accesses';
import {getItem, isAlphaNumericValue} from 'utils/Helper';

const AccessProtected = ({children}) => {
  const {pathname} = useLocation();

  const resourceList =
    (localStorage && getItem(localStorage, 'resource_access')) || [];
  const arrayString = resourceList?.split(',');

  let hasAccess;
  let splittedPath = pathname.split('/');

  // this condition is only for id which has alpha numeric value
  if (isAlphaNumericValue(splittedPath.at(-1))) {
    // remove last element which is number and make the main url
    splittedPath.pop();
    splittedPath = splittedPath.join('/');

    const selectedURLAccess = Accesses.find(
      (el) => el?.url === splittedPath,
    )?.access_code?.toString();
    if (arrayString.includes(selectedURLAccess)) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }
  } else if (isNaN(+splittedPath.at(-1))) {
    // enter if condition if the last element of the array is not a number
    // select requested url access code from accesses list
    const selectedURLAccess = Accesses.find(
      (el) => el?.url === pathname,
    )?.access_code?.toString();
    if (arrayString.includes(selectedURLAccess)) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }
  } else {
    // remove last element which is number and make the main url
    splittedPath.pop();
    splittedPath = splittedPath.join('/');

    const selectedURLAccess = Accesses.find(
      (el) => el?.url === splittedPath,
    )?.access_code?.toString();
    if (arrayString.includes(selectedURLAccess)) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }
  }

  if (hasAccess) {
    return <>{children}</>;
  } else {
    Notification(
      'Sorry! You are not authorized to access the requested page!',
      'error',
    );
    return <Navigate to='/' />;
  }
};

export default AccessProtected;

AccessProtected.propTypes = {
  children: PropTypes.node,
};
