import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import {Avatar, Dropdown} from 'antd';
import {FaChevronDown} from 'react-icons/fa';
import {useThemeContext} from '../../../../../contexts/AppContextProvider/ThemeContextProvider';
import {useSidebarContext} from '../../../../../contexts/AppContextProvider/SidebarContextProvider';
import PropTypes from 'prop-types';
import {AuthContext} from 'contexts/AuthContextProvider/authContext';
import {getItem} from 'utils/Helper';
import {useTranslation} from 'react-i18next';
import {toTitleCase} from 'utils/Formatter';

import './index.style.less';

const UserInfo = ({hasColor}) => {
  const {themeMode} = useThemeContext();
  const {logout} = useContext(AuthContext);
  const navigate = useNavigate();
  const {sidebarColorSet} = useSidebarContext();
  const {isSidebarBgImage} = useSidebarContext();
  const {t} = useTranslation();

  const menu = [
    {
      label: (
        <div
          onClick={() =>
            navigate(
              `/user-manager/user-details/${getItem(localStorage, 'user_id')}`,
            )
          }>
          {t('int-my-profile')}
        </div>
      ),
      key: 'profile-title-lg',
    },
    {
      label: <div onClick={logout}>{t('int-log-out')}</div>,
      key: 'logout',
    },
  ];

  return (
    <>
      {hasColor ? (
        <div
          style={{
            backgroundColor: isSidebarBgImage
              ? ''
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor,
          }}
          className={clsx('cr-user-info cr-user-info-hasColor', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            className='user-profile-dropdown'
            menu={{items: menu, className: 'user-profile'}}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              <Avatar className='cr-user-info-avatar'>
                {toTitleCase(getItem(localStorage, 'username')?.charAt(0))}
              </Avatar>

              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3>{getItem(localStorage, 'username')}</h3>
                  <span className='cr-user-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
                <span className='cr-user-designation text-truncate'>
                  {getItem(localStorage, 'role_name')}
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
      ) : (
        <div
          className={clsx('cr-user-info', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            className='user-profile-dropdown'
            menu={{items: menu, className: 'user-profile'}}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              <Avatar className='cr-user-info-avatar'>
                {getItem(localStorage, 'username')?.charAt(0)?.toUpperCase()}
              </Avatar>

              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h5>{getItem(localStorage, 'username')}</h5>
                  <span className='cr-user-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
                <span className='cr-user-designation text-truncate'>
                  {getItem(localStorage, 'role_name')}
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
};
