import AccessProtected from 'lib/RouteProtection';
import {lazy} from 'react';

const CampaignCreate = lazy(() => import('./CampaignCreate'));
const CampaignList = lazy(() => import('./CampaignList'));
const ClusterMapping = lazy(() => import('./ClusterMapping'));
const CampaignSetting = lazy(() => import('./CampaignSetting'));
const CampaignDetails = lazy(() => import('./CampaignDetails'));
const CampaignUpdate = lazy(() => import('./CampaignUpdate'));
const CampaignTargetSettings = lazy(() => import('./CampaignTargetSetting'));
const AssignBR = lazy(() => import('./AssignBR'));

export const CampaignManagerConfig = [
  {
    path: '/campaign-manager/campaign-create',
    element: (
      <AccessProtected>
        <CampaignCreate />,
      </AccessProtected>
    ),
  },
  {
    path: '/campaign-manager/campaign-list',
    element: (
      <AccessProtected>
        <CampaignList />
      </AccessProtected>
    ),
  },
  {
    path: '/campaign-manager/campaign-details/:id',
    element: (
      <AccessProtected>
        <CampaignDetails />
      </AccessProtected>
    ),
  },
  {
    path: '/campaign-manager/campaign-update/:id',
    element: (
      <AccessProtected>
        <CampaignUpdate />
      </AccessProtected>
    ),
  },
  {
    path: '/campaign-manager/cluster-mapping/:id',
    element: (
      <AccessProtected>
        <ClusterMapping />
      </AccessProtected>
    ),
  },
  {
    path: '/campaign-manager/campaign-setting',
    element: (
      <AccessProtected>
        <CampaignSetting />
      </AccessProtected>
    ),
  },
  {
    path: '/campaign-manager/campaign-config/:id',
    element: (
      <AccessProtected>
        <CampaignTargetSettings />,
      </AccessProtected>
    ),
  },
  {
    path: '/campaign-manager/assign-br/:id',
    element: (
      <AccessProtected>
        <AssignBR />
      </AccessProtected>
    ),
  },
];
