export const useDisableDevTool = () => {
  document.addEventListener('contextmenu', (e) => e.preventDefault());

  const ctrlShiftKey = (e, keyCode) => {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  };

  document.onkeydown = (e) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.keyCode === 123 ||
      ctrlShiftKey(e, 'I') ||
      ctrlShiftKey(e, 'J') ||
      ctrlShiftKey(e, 'C') ||
      (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
    )
      return false;
  };
};
