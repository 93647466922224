export const bdtFormat = (amount) => {
  return new Intl.NumberFormat('en-BD', {
    style: 'currency',
    currency: 'BDT',
  }).format(amount);
};

export const phpFormat = (amount) => {
  let bdt = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
  });

  return bdt.format(amount);
};

export const formatNumber = (amount) => {
  return new Intl.NumberFormat('en-US').format(amount);
};

export const toTitleCase = (str) => {
  str = str.replace(/[_-]/g, ' '); // Remove underscores and dashes
  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};
