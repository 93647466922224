import {Breadcrumb, Card, Divider, Row, Typography} from 'antd';
import {array, node, string} from 'prop-types';

import AppAnimateGroup from '@crema/core/AppAnimateGroup';
import AppPageMetadata from '@crema/core/AppPageMetadata';

const AppPageHeader = ({title, routeInfo, children}) => {
  return (
    <div style={{position: 'sticky', top: 0, zIndex: 10}}>
      <AppPageMetadata title={title} />
      <AppAnimateGroup type='top' height='auto'>
        <Card className='app-header-card' key='header-comp'>
          <Row justify='space-between' align='middle'>
            <Typography.Title level={3} style={{margin: 0}}>
              {title}
            </Typography.Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                gap: 10,
              }}>
              {children}
            </div>
          </Row>
          {routeInfo?.length > 0 && (
            <>
              <Divider style={{margin: '12px 0'}} />
              <Breadcrumb>
                {routeInfo?.map((el, i) => (
                  <Breadcrumb.Item key={i}>
                    <a href={el.link}>{el.title}</a>
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </>
          )}
        </Card>
      </AppAnimateGroup>
    </div>
  );
};

AppPageHeader.defaultProps = {
  routeInfo: null,
};

AppPageHeader.propTypes = {
  title: string,
  children: node,
  routeInfo: array,
};

export default AppPageHeader;
