import logo from './../../../../../assets/icon/logo.svg';
import {useAuthContext} from 'contexts/AuthContextProvider/authContext';

import './index.style.less';

const AppLogo = () => {
  const {theme} = useAuthContext();

  return (
    <div className='app-logo'>
      {theme.logo ? (
        <img src={`${process.env.REACT_APP_cdn}${theme.logo}`} alt='ppl-logo' />
      ) : (
        <img src={logo} alt='ppl-logo' />
      )}
    </div>
  );
};

export default AppLogo;
