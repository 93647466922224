import {useMutation} from 'react-query';
import {AuthAPI, OrganizationManagerAPI} from 'utils/ApiConfig';

import ErrorHandler from 'utils/ErrorHandler';
import {getItem} from 'utils/Helper';

const queryOptions = {
  onError: ErrorHandler,
};

export const useLogin = () => {
  return useMutation(
    (body) => AuthAPI.post('/auth/signin-web', body),
    queryOptions,
  );
};

export const useFetchOrgTheme = () => {
  return useMutation(
    (id) =>
      OrganizationManagerAPI.get(`/org/theme/${id}`, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    queryOptions,
  );
};
