import React, {lazy} from 'react';

const Signin = lazy(() => import('./Signin'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const VerifyOtp = lazy(() => import('./VerifyOtp'));
const NewPassword = lazy(() => import('./NewPassword'));
const WebPrivacyPolicy = lazy(() => import('./PrivacyPolicy/Web'));
const MobilePrivacyPolicy = lazy(() => import('./PrivacyPolicy/Mobile'));

export const authRouteConfig = [
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/verify-otp',
    element: <VerifyOtp />,
  },
  {
    path: '/reset-password',
    element: <NewPassword />,
  },
  {
    path: '/app-privacy-policy',
    element: <MobilePrivacyPolicy />,
  },
  {
    path: '/web-privacy-policy',
    element: <WebPrivacyPolicy />,
  },
];
