import {Col, Row, Typography} from 'antd';
import {bool, string, any} from 'prop-types';

const {Title} = Typography;

import './index.style.less';

const AppListCardItem = ({title, value, color, ...others}) => {
  return (
    <div className='custom-list-bg' {...others}>
      <Row style={{backgroundColor: `${color && 'rgba(3, 88, 140, 0.02)'}`}}>
        <Col xs={8}>
          <Title level={5} style={{fontWeight: 'bold'}}>
            {title}
          </Title>
        </Col>
        <Col xs={16}>
          <Title level={5} className='vl-title' style={{fontWeight: 'normal'}}>
            <div style={{marginTop: '-1.5rem', marginLeft: '1rem'}}>
              {value}
            </div>
          </Title>
        </Col>
      </Row>
    </div>
  );
};

AppListCardItem.propTypes = {
  title: string,
  value: any,
  color: bool,
};

export default AppListCardItem;
