import PropTypes from 'prop-types';
import {List, Collapse} from 'antd';
import moment from 'moment';
import {getItem} from 'utils/Helper';
import {NotificationManagerAPI} from 'utils/ApiConfig';

import './NotificationItem.less';

const {Panel} = Collapse;

const NotificationItem = ({item, setNotifications}) => {
  const getDifference = (time) => {
    return moment(time).fromNow();
  };

  const onNotificationExpand = async (e) => {
    try {
      e.stopPropagation();
      if (item.seen === 0) {
        await NotificationManagerAPI.get(`/notification/seen/${item.id}`, {
          headers: {
            Authorization: `Bearer ${getItem(localStorage, 'token')}`,
          },
        });

        setNotifications((notifications) =>
          notifications.map((notification) => {
            if (notification.id === item.id) {
              notification.seen = 1;
            }
            return notification;
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <List.Item className='notify-listItem ' onClick={onNotificationExpand}>
      <List.Item.Meta
        className={item.seen ? '' : 'not-seen'}
        avatar={
          <div
            className='circle'
            style={{
              backgroundColor:
                item.type == 'info'
                  ? '#61BDD4'
                  : item.type === 'success'
                  ? '#87B881'
                  : '#F3C458',
            }}></div>
        }
        title={item.from_name}
        description={
          <div>
            <Collapse bordered={false}>
              <Panel header={item.header} key={item?.id}>
                <p className='text'>{item.description}</p>
              </Panel>
            </Collapse>
            <div className='time'>{getDifference(item.created_at)}</div>
          </div>
        }
      />
    </List.Item>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  setNotifications: PropTypes.func.isRequired,
};
