import {lazy} from 'react';

const FFLiveLocationReport = lazy(() => import('./FFLiveLocationReport'));
const FFContactLocationReport = lazy(() => import('./FFContactLocationReport'));

export const LiveLocationManagerConfig = [
  {
    path: '/dashboard/ff-live-location-report',
    element: <FFLiveLocationReport />,
  },
  {
    path: '/dashboard/ff-contact-location-report',
    element: <FFContactLocationReport />,
  },
];
