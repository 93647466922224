import {lazy} from 'react';

import AccessProtected from 'lib/RouteProtection';

const RetailLanding = lazy(() => import('./RetailLanding'));
const RetailerList = lazy(() => import('./Retailers/RetailerList'));
const ProgramList = lazy(() => import('./SalesProgram/ProgramList'));
const ProgramCreate = lazy(() => import('./SalesProgram/CreateProgram'));
const ProgramUpdate = lazy(() => import('./SalesProgram/UpdateProgram'));
const ProgramDetails = lazy(() => import('./SalesProgram/ProgramDetails'));
const AssignFF = lazy(() => import('./SalesProgram/AssignFF'));
const CreateRetailer = lazy(() => import('./Retailers/CreateRetailer'));
const UpdateRetailer = lazy(() => import('./Retailers/UpdateRetailer'));
const RetailerDetails = lazy(() => import('./Retailers/RetailerDetails'));
const InvoiceList = lazy(() => import('./Invoices/InvoiceList'));
const InvoiceDetails = lazy(() => import('./Invoices/InvoiceDetails'));

export const RetailManagerConfig = [
  {
    path: '/retail-manager/landing',
    element: (
      <AccessProtected>
        <RetailLanding />
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/retail-create',
    element: (
      <AccessProtected>
        <CreateRetailer />,
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/retail-update/:param',
    element: (
      <AccessProtected>
        <UpdateRetailer />
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/retail-details/:param',
    element: (
      <AccessProtected>
        <RetailerDetails />
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/retail-list',
    element: (
      <AccessProtected>
        <RetailerList />,
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/program/program-list',
    element: (
      <AccessProtected>
        <ProgramList />,
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/program/program-create',
    element: (
      <AccessProtected>
        <ProgramCreate />
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/program/program-update/:id',
    element: (
      <AccessProtected>
        <ProgramUpdate />
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/program/program-details/:id',
    element: (
      <AccessProtected>
        <ProgramDetails />
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/program/ff-assignment/:id',
    element: (
      <AccessProtected>
        <AssignFF />
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/invoice/invoice-list',
    element: (
      <AccessProtected>
        <InvoiceList />
      </AccessProtected>
    ),
  },
  {
    path: '/retail-manager/invoice/details/:param',
    element: (
      <AccessProtected>
        <InvoiceDetails />
      </AccessProtected>
    ),
  },
];
