import React from 'react';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';

import {userManagerConfig} from './UserManager';
import Error403 from './errorPages/Error403';
import Error404 from './errorPages/Error404';
import {organizationManagerConfig} from './OrganizationManager';
import {resourceManagerConfig} from './ResourceManager';
import {roleManagerConfig} from './RoleManager';
import {locationManagerConfig} from './LocationManager';
import {CampaignManagerConfig} from './CampaignManager';
import {NotificationManagerConfig} from './NotificationManager';
import {DashboardManagerConfig} from './DashboardManager';
import {ReportManagerConfig} from './ReportManager';
import {LiveLocationManagerConfig} from './LiveLocationManager';
import {GdsdashboardManagerConfig} from './GdsDashbordManager';
import {ffManagerConfig} from './FFManager';
import {hierarchyManagerConfig} from './LocationHierarchy';
import {hrManagerConfig} from './HRManager';
import {categoryManagerConfig} from './CategoryManager';
import {productManagerConfig} from './ProductManager';
import {materialManagerConfig} from './MaterialManager';
import {AgencyManagerConfig} from './AgencyManager';
import {RetailManagerConfig} from './RetailManager';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...userManagerConfig,
    ...organizationManagerConfig,
    ...resourceManagerConfig,
    ...roleManagerConfig,
    ...locationManagerConfig,
    ...CampaignManagerConfig,
    ...NotificationManagerConfig,
    ...DashboardManagerConfig,
    ...ReportManagerConfig,
    ...LiveLocationManagerConfig,
    ...GdsdashboardManagerConfig,
    ...ffManagerConfig,
    ...hierarchyManagerConfig,
    ...hrManagerConfig,
    ...categoryManagerConfig,
    ...productManagerConfig,
    ...materialManagerConfig,
    ...AgencyManagerConfig,
    ...RetailManagerConfig,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      exact: true,
      element: <Error404 />,
    },
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
