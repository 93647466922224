import {useEffect, useState} from 'react';
import io from 'socket.io-client';

import {getItem} from 'utils/Helper';

const getDeviceInfo = () => {
  const navigator_info = window.navigator;
  const screen_info = window.screen;
  let devideId = navigator_info.mimeTypes.length;

  devideId += navigator_info.userAgent.replace(/\D+/g, '');
  devideId += navigator_info.plugins.length;
  devideId += screen_info.height || '';
  devideId += screen_info.width || '';
  devideId += screen_info.pixelDepth || '';

  return devideId;
};

const color = 'color: #00FF00';

const useSocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketOption = {transports: ['websocket', 'polling']};

    if (!process.env.REACT_APP_socket_url.includes('http://127.0.0.1')) {
      socketOption.path = '/notification-manager/socket.io';
    }

    setSocket(io(process.env.REACT_APP_socket_url, socketOption));
  }, []);

  useEffect(() => {
    socket?.on('connect', () => console.log('%cSocket connected', color));

    socket?.on('registered', (data) => {
      console.log('%cSocket registered: ', color, data);
    });

    socket?.on('disconnect', () => {
      console.log('%cSocket disconnected from server', color);
    });

    socket?.on('error', (error) => {
      console.error('Socket Error:', error);
    });

    socket?.on('exception', (error) => {
      console.error(error.message);
    });

    return () => socket?.disconnect();
  }, [socket]);

  const registerSocket = () => {
    socket.emit('register', {
      jwt: getItem(localStorage, 'token'),
      device_id: getDeviceInfo(),
    });
  };

  const disconSocket = () =>
    socket.emit('logout', {jwt: getItem(localStorage, 'token')});

  return {
    socket,
    registerSocket,
    disconSocket,
  };
};

export default useSocket;
