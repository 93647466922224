import PropTypes from 'prop-types';
import {bool} from 'prop-types';

const AppLabel = ({children, required}) => {
  return (
    <label
      htmlFor={children}
      className={required ? 'required' : ''}
      style={{fontWeight: 500}}>
      {children}
    </label>
  );
};

AppLabel.propTypes = {
  children: PropTypes.node,
  required: bool,
};
export default AppLabel;
