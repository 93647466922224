export const nidNumberMatch = /^[0-9]{10}$|^[0-9]{13}$|^[0-9]{17}$/;
export const birthIdNumberMatch = /^[0-9]{17}$/;
// export const passportNumberMatch = /^[0-9]{17}$/;
// export const passportNumberMatch = /^(?!^0+$)[a-zA-Z0-9]{9}$/;
export const passportNumberMatch = /[A-Z]{2}[0-9]{7}/;
export const phoneNumberValidationRegex = /(^(01){1}[3456789]{1}(\d){8})$/;
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/;
export const numberValidate = /^-?\d+$/;
export const nameValidate = /^[^\s\d].*[^\s\d]$/;
export const addressValidation = /^.{5,}$/;
export const vatValidation = /[+-]?([0-9]*[.])?[0-9]+/;
export const priceValidation =
  /^(?:0.[0-9]{1,2}|[1-9]{1}[0-9]*(.[0-9]{1,2})?|0)$/;
export const unitValidation = /^[1-9]\d*$/;
export const mrpValidation = /^[0-9]*$/;

export const isValidEmail = (value) => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};
export const usernameValidation =
  /^(?=.*[a-zA-Z])[a-zA-Z0-9]+([_-][a-zA-Z0-9]+)*$/;
