export const Accesses = [
  {url: '/dashboard/report/custom-report', access_code: 115},
  {url: '/dashboard/report/report-list', access_code: 118},
  {url: '/dashboard/report/query-builder', access_code: 119},
  {url: '/dashboard/report/query-update', access_code: 122},

  {url: '/dashboard/gds-dashboard', access_code: 116},
  {url: '/dashboard/gds-dashboard/view', access_code: 116},
  {url: '/dashboard/gds-dashboard/dashboard-list', access_code: 124},
  {url: '/dashboard/gds-dashboard/custom-dashboard', access_code: 125},
  {url: '/dashboard/gds-dashboard/update', access_code: 127},

  {url: '/user-manager/user-create', access_code: 41},
  {url: '/user-manager/user-update', access_code: 43},
  {url: '/user-manager/user-list', access_code: 40},
  {url: '/user-manager/user-details', access_code: 42},

  {url: '/role-manager/role-create', access_code: 47},
  {url: '/role-manager/role-update', access_code: 48},
  {url: '/role-manager/role-list', access_code: 46},

  {url: '/product-manager/product-list', access_code: 151},

  {url: '/campaign-manager/campaign-list', access_code: 76},
  {url: '/campaign-manager/cluster-mapping', access_code: 100},
  {url: '/campaign-manager/campaign-setting', access_code: 138},
  {url: '/campaign-manager/campaign-config', access_code: 208},
  {url: '/campaign-manager/assign-br', access_code: 142},
  {url: '/campaign-manager/campaign-create', access_code: 75},
  {url: '/campaign-manager/campaign-update', access_code: 139},
  {url: '/campaign-manager/campaign-details', access_code: 192},
  {url: '/organization-manager/resource-list', access_code: 52},
  {url: '/organization-manager/resource-create', access_code: 52},
  {url: '/organization-manager/resource-update', access_code: 53},

  {url: '/organization-manager/settings', access_code: 38},
  {url: '/organization-manager/theme-setup', access_code: 82},

  {url: '/notification-manager/notification-list', access_code: 67},
  {url: '/notification-manager/create', access_code: 68},
  {url: '/notification-manager/notification-details', access_code: 147},
  {url: '/notification-manager/notification-edit', access_code: 68},

  {url: '/organization-manager/location-list', access_code: 79},
  {url: '/organization-manager/location-create', access_code: 78},
  {url: '/organization-manager/location-update', access_code: 81},
  {url: '/organization-manager/add-children-location', access_code: 101},

  {url: '/agency-manager/agency-list', access_code: 188},
  {url: '/agency-manager/agency-create', access_code: 189},
  {url: '/agency-manager/agency-update', access_code: 190},

  // ff manager

  {url: '/ff-manager/ff-list', access_code: 107},
  {url: '/ff-manager/ff-create', access_code: 106},
  {url: '/ff-manager/ff-details', access_code: 108},
  {url: '/ff-manager/ff-update', access_code: 109},

  //Hierarchy Manager
  {url: '/organization-manager/hierarchy-list', access_code: 102},
  {url: '/organization-manager/hierarchy-create', access_code: 103},
  {url: '/organization-manager/hierarchy-update', access_code: 104},

  // hr manager
  {url: '/hr-manager/hr-letter', access_code: 130},

  //Category Manager
  {url: '/organization-manager/category-list', access_code: 102},
  {url: '/organization-manager/category-create', access_code: 103},
  {url: '/organization-manager/category-update', access_code: 104},
  {url: '/organization-manager/add-children-category', access_code: 131},
  {
    url: '/material-manager/material-list',
    access_code: 164,
  },
  {
    url: '/material-manager/material-create',
    access_code: 165,
  },
  {
    url: '/material-manager/material-update',
    access_code: 166,
  },
  {
    url: '/material-manager/assign-material',
    access_code: 167,
  },

  // retail manager
  {url: '/retail-manager/landing', access_code: 85},
  {url: '/retail-manager/retail-list', access_code: 201},
  {url: '/retail-manager/retail-create', access_code: 202},
  {url: '/retail-manager/retail-details', access_code: 205},
  {url: '/retail-manager/retail-update', access_code: 203},
  {url: '/retail-manager/program/program-list', access_code: 194},
  {url: '/retail-manager/program/program-create', access_code: 196},
  {url: '/retail-manager/program/program-update', access_code: 204},
  {url: '/retail-manager/program/program-details', access_code: 197},
  {url: '/retail-manager/program/ff-assignment', access_code: 195},
  {url: '/retail-manager/invoice/invoice-list', access_code: 206},
  {url: '/retail-manager/invoice/details', access_code: 207},

  // live location reports
  // {url: '/dashboard/ff-live-location-report', access_code: 103},
  // {url: '/dashboard/ff-contact-location-report', access_code: 103},
];
