import {
  createContext,
  useState,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {changeAntdTheme} from 'dynamic-antd-theme';
import {useTranslation} from 'react-i18next';

import isAuthenticated from '../../utils/Auth/isAuthenticated';
import {removeAll, setItem} from 'utils/Helper';
import AppIcon from 'components/AppComponents/AppIcon';
import {hasAccess} from 'utils/Helper';
import hexToRGBA from 'utils/hexToRgba';
import {materialListUrl} from 'pages/MaterialManager';
import useSocket from 'hooks/useSocket';
import {useFetchOrgTheme, useLogin} from 'api/Auth';

export const AuthContext = createContext({});

export const useAuthContext = () => useContext(AuthContext);

const root = document.documentElement;
const changeFontFamily = (fontFamily) => {
  const head = document.head,
    link = document.createElement('link');

  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400;500&display=swap`;
  head.appendChild(link);

  document.body.style.fontFamily = `"${fontFamily}", sans-serif`;
};

let orgTheme = localStorage.getItem('org_theme');
if (orgTheme) {
  orgTheme = JSON.parse(orgTheme);
  root.style.setProperty('--bg-color', hexToRGBA(orgTheme.theme_color, 0.02));
  changeAntdTheme(orgTheme.theme_color);
  root.style.setProperty('--secondary-color', orgTheme.header_color);
  changeFontFamily(orgTheme.font_family);
  root.style.setProperty('--font-size-custom', `${orgTheme.font_size}px`);

  const favicon = document.querySelector('[rel=icon]');
  favicon.href = `${process.env.REACT_APP_cdn}${orgTheme?.logo}`;
} else {
  orgTheme = {};
}

const INITIALTHEME = {
  primaryColor: orgTheme.theme_color ? orgTheme.theme_color : '#001529',
  secondaryColor: orgTheme.header_color ? orgTheme.header_color : '#f04f47',
  bgColor: orgTheme.theme_color
    ? hexToRGBA(orgTheme.theme_color, 0.02)
    : '#f4f7fe',
  sidebarSelectedBG: orgTheme.theme_color
    ? hexToRGBA(orgTheme.theme_color, 0.1)
    : '#F4F7FE',
  fontSize: orgTheme.font_size ? orgTheme.font_size : 14,
  fontFamily: orgTheme.font_family ? orgTheme.font_family : 'Poppins',
  logo: orgTheme.logo ? orgTheme.logo : '',
};

export const PRIMARY = 'PRIMARY';
export const SECONDARY = 'SECONDARY';
export const FONTSIZE = 'FONTSIZE';
export const FONTFAMILY = 'FONTFAMILY';
export const LOGO = 'LOGO';

const themeReducer = (state, action) => {
  const {
    payload: {primaryColor, secondaryColor, fontFamily, fontSize, logo},
    type,
  } = action;

  switch (type) {
    case PRIMARY: {
      const bgColor = hexToRGBA(primaryColor, 0.02);
      root.style.setProperty('--bg-color', state.bgColor);
      changeAntdTheme(primaryColor);

      return {
        ...state,
        primaryColor,
        bgColor,
        sidebarSelectedBG: hexToRGBA(primaryColor, 0.1),
      };
    }
    case SECONDARY:
      root.style.setProperty('--secondary-color', secondaryColor);
      return {
        ...state,
        secondaryColor,
      };

    case FONTFAMILY:
      changeFontFamily(fontFamily);
      return {
        ...state,
        fontFamily,
      };
    case FONTSIZE:
      root.style.setProperty('--font-size-custom', `${fontSize}px`);
      return {
        ...state,
        fontSize,
      };
    case LOGO:
      return {...state, logo};
    default:
      break;
  }
};

const changeTheme = (theme, dispatch) => {
  if (theme.theme_color) {
    dispatch({
      type: PRIMARY,
      payload: {primaryColor: theme.theme_color},
    });
  }
  if (theme.header_color) {
    dispatch({
      type: SECONDARY,
      payload: {secondaryColor: theme.header_color},
    });
  }
  if (theme.font_family) {
    dispatch({
      type: FONTFAMILY,
      payload: {fontFamily: theme.font_family},
    });
  }
  if (theme.font_size) {
    dispatch({
      type: FONTSIZE,
      payload: {fontSize: theme?.font_size},
    });
  }

  if (theme.logo) {
    dispatch({
      type: LOGO,
      payload: {logo: theme?.logo},
    });
  }
};

export const AuthContextProvider = ({children}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {registerSocket, disconSocket, socket} = useSocket();

  const {mutate: mutateLogin, isLoading: isLoging} = useLogin();
  const {mutate: mutateTheme, isLoading: isThemeLoading} = useFetchOrgTheme();
  const isLoading = isLoging || isThemeLoading;

  const [authUser, setAuthUser] = useState({
    isLoading: isLoading,
    isAuthenticated: isAuthenticated(),
    user: {
      role: 'user',
    },
  });
  const [theme, dispatchTheme] = useReducer(themeReducer, INITIALTHEME);

  useEffect(() => {
    if (localStorage.getItem('token') && socket) registerSocket();
  }, [socket]);

  const login = async (values) => {
    mutateLogin(
      {
        username: values.email,
        password: values.password,
      },
      {
        onSuccess: ({data: {data}}) => {
          setItem(localStorage, 'token', data.token);
          setItem(localStorage, 'org_info', JSON.stringify(data.org_info));
          setItem(localStorage, 'role', data?.role.toString());
          setItem(localStorage, 'isLoggedin', 'true');
          setItem(localStorage, 'username', data?.username);
          setItem(localStorage, 'user_id', data?.id.toString());
          setItem(localStorage, 'role_name', data?.role_name);
          setItem(
            localStorage,
            'resource_access',
            data?.resource_access?.toString(),
          );
          setItem(localStorage, 'user_type', data?.user_type);
          if (data?.user_type === 'org_mgt') {
            localStorage.setItem('userRoleTypeId', 28);
          } else {
            localStorage.setItem('userRoleTypeId', 27);
          }
          setItem(localStorage, 'role_access', data?.role_access.toString());
          setAuthUser({
            isLoading: false,
            isAuthenticated: true,
            user: {
              displayName: data?.username,
              photoURL: 'https://unsplash.com/photos/iEEBWgY_6lA',
              role: 'user',
            },
          });

          if (socket) {
            socket.connect();
            registerSocket();
          }

          mutateTheme(data.org_info?.id, {
            onSuccess: ({data: {data: theme}}) => {
              changeTheme(theme.org_theme, dispatchTheme);

              if (theme?.org_theme) {
                localStorage.setItem(
                  'org_theme',
                  JSON.stringify(theme?.org_theme),
                );

                localStorage.setItem('logo', theme?.org_theme?.logo);
              }
            },
          });
        },
      },
    );
  };

  const logout = () => {
    disconSocket();

    removeAll(localStorage);
    setAuthUser({
      isLoading: false,
      isAuthenticated: false,
      user: {},
    });

    navigate('/signin');
  };

  const routeItems = [
    {
      id: 'user-manager',
      title: t('int-users'),
      icon: <AppIcon tag='mdi:user-supervisor' />,
      path: '/user-manager/user-list',
      access_code: 35,
    },
    {
      id: 'role-manager',
      title: t('int-role'),
      icon: <AppIcon tag='bxs:user-check' />,
      path: '/role-manager/role-list',
      access_code: 36,
    },
    {
      id: 'ff-manager',
      title: t('int-ff'),
      icon: <AppIcon tag='fa-solid:users' />,
      path: '/ff-manager/ff-list',
      access_code: 105,
    },
    {
      id: 'product-manager',
      title: t('int-product'),
      icon: <AppIcon tag='fa6-solid:box-open' />,
      path: '/product-manager/product-list',
      access_code: 151,
    },
    {
      id: 'campaign-manager',
      title: t('int-campaign'),
      icon: <AppIcon tag='material-symbols:campaign' />,
      path: '/campaign-manager/campaign-list',
      access_code: 37, //74
    },
    {
      id: 'material-manager',
      title: t('int-material'),
      icon: <AppIcon tag='fa-solid:users' />,
      path: materialListUrl,
      access_code: 163,
    },
    {
      id: 'hr-manager',
      title: t('int-hr'),
      icon: <AppIcon tag='fa-solid:users-cog' />,
      path: '/hr-manager/hr-letter',
      access_code: 129,
    },
    {
      id: 'notification-manager',
      title: t('int-notifications'),
      icon: <AppIcon tag='ri:user-settings-fill' />,
      path: '/notification-manager/notification-list',
      access_code: 39,
    },
    {
      id: 'agency-manager',
      title: t('int-affiliates'),
      icon: <AppIcon tag='ri:home-gear-fill' />,
      path: '/agency-manager/agency-list',
      access_code: 188,
    },
    {
      id: 'retail-manager',
      title: t('int-retail'),
      icon: <AppIcon tag='fa6-solid:shop' />,
      path: '/retail-manager/landing',
      access_code: 85,
    },
    {
      id: 'organization-manager',
      title: t('int-organization'),
      icon: <AppIcon tag='ci:settings-filled' />,
      path: '/organization-manager/settings',
      access_code: 38,
    },
  ];
  let hasAccessRoutes = [
    {
      id: 'dashboard',
      title: t('int-home'),
      icon: <AppIcon tag='ant-design:home-filled' />,
      path: '/dashboard/home',
    },
  ];
  routeItems.map(
    (el) => hasAccess(el?.access_code) && hasAccessRoutes.push(el),
  );

  return (
    <AuthContext.Provider
      value={{
        authUser,
        theme,
        dispatchTheme,
        login,
        logout,
        routesConfig: hasAccessRoutes,
        isLoading,
        socket,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
